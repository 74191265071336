import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';
import AnimatedPlayButton from '~components/AnimatedPlayButton';
import { lineFix } from '~utils/popup-helpers';
import { LabelPremium, LabelOverlay } from '~components/playlist-card/PlaylistLabels';
import { boolFlagFromString } from '~utils/playlist-helpers';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    marginBottom: '30px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '45px'
    }
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#000000',
    opacity: 0.87
  },
  infoText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#2E72AC',
    marginBottom: '29px'
  },
  heroImage: {
    maxWidth: '100%',
    display: 'flex',
    marginTop: '24px',
    height: 'auto'
  },
  containerPosition: {
    marginTop: '50px'
  },
  featuredVideoTitle: {
    fontWeight: '500'
  }
}));

const TopicTemplateHero = ({ title, subtitle, featuredVideo }) => {
  const classes = useStyles();
  const isPremium = boolFlagFromString(featuredVideo.label_is_premium);
  return (
    <Container center className={classes.containerPosition}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h2" align="left" className={classes.heading}>
            {lineFix(title)}
          </Typography>

          <Typography variant="h2" align="left" className={classes.subtitle}>
            {lineFix(subtitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline" className={classes.infoText}>
            FEATURED VIDEO
          </Typography>
          <Box
            position="relative"
            component={Link}
            to={featuredVideo.url}
            display="inline-block"
            width="100%"
          >
            <img
              className={classes.heroImage}
              src={featuredVideo.cover_image.url}
              alt={featuredVideo.cover_image.alt}
            />
            <AnimatedPlayButton />
          </Box>
          <Typography variant="h5" className={classes.featuredVideoTitle}>
            {lineFix(featuredVideo.title)}
          </Typography>
          <Box position="relative" pt={1}>
            {isPremium && (
              <LabelOverlay className={classes.overlay}>
                {isPremium && <LabelPremium />}
              </LabelOverlay>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

TopicTemplateHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  featuredVideo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({}).isRequired,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    label_is_audio: PropTypes.string,
    label_is_new: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        length_in_seconds: PropTypes.number
      })
    ).isRequired
  }).isRequired
};

TopicTemplateHero.defaultProps = {
  subtitle: ''
};

export default TopicTemplateHero;
